<template>
  <b-modal
    v-model="_state"
    size="md"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="resetForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("importUsers") }}
      </h5>
    </template>
    <template v-slot:default>
      <file-drop-uploader :fileTypes="allowedFileTypes" @addFiles="addFiles" />
      <div class="mt-5" v-html="$t('uploadedFilename', { filename })"></div>
      <hr class="my-3 mx-n3" />
      <b-row class="no-gutters justify-content-end">
        <b-button
          variant="outline-dark"
          class="mr-1"
          @click="setModalState(false)"
        >
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          class="text-white"
          variant="primary"
          :disabled="isImportDisabled"
          @click="uploadUserList"
        >
          {{ $t("button.insert") }}
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import FileDropUploader from "../Base/BaseFileDropUploader";
export default {
  components: {
    FileDropUploader
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: "",
      fileTypes: [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ],
      fileTypesShort: [".xls", ".xlsx"]
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    },
    allowedFileTypes() {
      return this.fileTypes.join(",");
    },
    filename() {
      return this.file ? this.file.name : "-";
    },
    isImportDisabled() {
      return this.file === null;
    },
    group() {
      return this.$store.getters["groups/getCurrentGroup"];
    }
  },
  methods: {
    resetForm() {
      this.file = null;
    },
    addFiles(files) {
      this.file = files[0];
    },
    setModalState(value) {
      this.$emit("update:state", value);
    },
    async uploadUserList() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("groups/uploadGroupMembers", {
          groupId: this.group._id,
          file: this.file
        });
        await this.$store.dispatch("groups/retrieveGroupUsers");

        this.setModalState(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
