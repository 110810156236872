<template>
  <div>
    <b-row>
      <b-col>
        <h3 class="mb-3">
          <span class="text-secondary">
            {{ $t("page.groupManagement.title") }} -
          </span>
          <span v-if="!editNameActive">{{ group.name }}</span>
          <b-icon
            v-if="!editNameActive"
            icon="pencil-square"
            variant="primary"
            class="pl-2"
            style="cursor: pointer;"
            @click="onEditGroupName"
          ></b-icon>
          <span v-else>
            <b-form-input
              class="mt-2"
              size="sm"
              style="max-width: 15em"
              v-model="groupName"
            ></b-form-input>
            <b-button
              pill
              size="sm"
              class="text-white mr-2"
              @click="updateGroupName"
            >
              {{ $t("button.save") }}
            </b-button>
            <b-button
              pill
              size="sm"
              class="text-white"
              @click="editNameActive = false"
            >
              {{ $t("button.cancel") }}
            </b-button>
          </span>
        </h3>
      </b-col>
      <b-col>
        <b-button
          class="mr-1 mt-1"
          variant="secondary float-right"
          size="sm"
          @click="$router.push({ name: 'Groups' })"
        >
          {{ $t("button.backToGroups") }}
        </b-button>
      </b-col>
    </b-row>
    <table class="mb-5">
      <tr>
        <td>
          <div class="mb-2 mr-3">{{ $t("page.groupManagement.comments") }}</div>
        </td>
        <td>
          <base-switcher
            v-model="commentPermissions"
            name="commentPermissions"
            @change="handleChange"
            sync
          />
        </td>
      </tr>
      <tr>
        <td>
          <div class="mb-2 mr-3">{{ $t("page.groupManagement.status") }}</div>
        </td>
        <td>
          <base-switcher
            v-model="activityStatus"
            name="activityStatus"
            @change="handleChange"
            sync
          />
        </td>
      </tr>
    </table>

    <group-management-filters class="mb-3" />

    <b-row class="no-gutters ml-n3 mb-3 justify-content-between">
      <b-col class="col-12 col-md-auto ml-3">
        <b-button
          class="mr-1 mt-1"
          variant="secondary"
          size="sm"
          :disabled="isGroupUsersEmpty"
          @click="selectAllUsers"
        >
          {{ $t("button.selectAll") }}
        </b-button>
        <b-button
          class="mt-1"
          variant="secondary"
          size="sm"
          :disabled="isGroupUsersEmpty"
          @click="clearAllSelectedUsers"
        >
          {{ $t("button.clearAll") }}
        </b-button>
      </b-col>

      <b-col class="col-12 col-md-auto mt-md-0 ml-3">
        <b-button
          class="mr-1 mt-1"
          variant="secondary"
          size="sm"
          :disabled="isSelectedUsersEmpty"
          @click="updateUsersRoles"
        >
          {{ $t("button.publishAccess") }}
        </b-button>
        <b-button
          class="mr-1 mt-1"
          variant="secondary"
          size="sm"
          @click="openInsertUserModal"
        >
          {{ $t("button.createUser") }}
        </b-button>
        <b-button
          class="mt-1"
          variant="secondary"
          size="sm"
          @click="openImportUserModal"
        >
          {{ $t("button.importUsers") }}
        </b-button>
      </b-col>
    </b-row>

    <user-table
      ref="userTableRef"
      :users="formatedUsers"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :selectable="true"
      @setSelectedItems="setSelectedUsers"
    />

    <insert-user-modal
      :state.sync="insertUserModalState"
      @onSubmit="addGroupMember"
    />
    <import-users :state.sync="importUserModalState" />
  </div>
</template>

<script>
import userMixin from "../mixins/userMixin";

import BaseSwitcher from "../components/Base/Switcher";
import GroupManagementFilters from "../components/Groups/GroupManagementFilters";
import UserTable from "../components/User/UserTable";
import InsertUserModal from "../components/Groups/InsertUserModal";
import ImportUsers from "../components/Groups/ImportUsers";

export default {
  components: {
    BaseSwitcher,
    GroupManagementFilters,
    UserTable,
    InsertUserModal,
    ImportUsers
  },
  mixins: [userMixin],
  data() {
    return {
      selectedUsers: [],
      fields: [
        { key: "selected", label: "selected" },
        { key: "fullName", label: "nameAndSurname", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "access", label: "access", sort: false },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.delete",
          variant: "orange",
          callbackFunc: this.openRemoveConfirmationModal
        }
      ],
      insertUserModalState: false,
      importUserModalState: false,
      testSwitcher: false,
      groupName: "",
      commentPermissions: false,
      activityStatus: false,
      editNameActive: false
    };
  },
  mounted() {
    this.commentPermissions = this.group.commentPermissions;
    this.activityStatus = this.group.status === "active";
  },
  computed: {
    group() {
      return this.$store.getters["groups/getCurrentGroup"];
    },
    groupUsers() {
      return this.$store.getters["groups/getGroupUsers"];
    },
    isGroupUsersEmpty() {
      return this.groupUsers.length === 0;
    },
    isSelectedUsersEmpty() {
      return this.selectedUsers.length === 0;
    },
    formatedUsers() {
      return this.groupUsers
        .filter(({ user }) => user)
        .map(groupUser => ({
          id: groupUser.user._id,
          photo: this.$_getUserProfilePhoto(groupUser.user.profile),
          fullName: this.$_getUserFullName(groupUser.user.profile),
          email: groupUser.user.email,
          access: groupUser.role,
          status: groupUser.user.status
        }));
    },
    sortBy: {
      get() {
        return this.$store.getters["groups/getManagementSortBy"];
      },
      set(value) {
        this.$store.commit("groups/SET_MANAGEMENT_SORT_BY", value);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["groups/getManagementSortDesc"];
      },
      set(value) {
        this.$store.commit("groups/SET_MANAGEMENT_SORT_DESC", value);
      }
    }
  },
  methods: {
    selectAllUsers() {
      this.$refs.userTableRef.selectAllRows();
    },
    clearAllSelectedUsers() {
      this.$refs.userTableRef.clearAllSelectedRows();
    },
    setSelectedUsers(users) {
      this.selectedUsers = users;
    },
    openInsertUserModal() {
      this.insertUserModalState = true;
    },
    openImportUserModal() {
      this.importUserModalState = true;
    },
    openRemoveConfirmationModal(userId) {
      const user = this.formatedUsers.find(u => u.id === userId);
      let userName = "";
      if (user) userName = user.fullName;

      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "confirm.removeUserTitle",
        messageText: "confirm.removeUserMessage",
        messageParams: { userName },
        confirmBtnCallback: this.removeUser,
        confirmBtnParams: { userId: user.id },
        type: "DELETE"
      });
    },
    onEditGroupName() {
      this.groupName = this.group.name;
      this.editNameActive = true;
    },
    async updateGroupName() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        const groupId = this.group._id;
        const payload = {
          name: this.groupName,
          status: this.activityStatus ? "active" : "inactive",
          commentPermissions: this.commentPermissions
        };

        await this.$store.dispatch("groups/updateGroup", {
          groupId,
          payload
        });

        await this.$store.dispatch("groups/retrieveGroup", groupId);

        this.editNameActive = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async handleChange() {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        const groupSwithes = {
          name: this.group.name,
          status: this.activityStatus ? "active" : "inactive",
          commentPermissions: this.commentPermissions
        };
        await this.$store.dispatch("groups/updateGroup", {
          groupId: this.group._id,
          payload: groupSwithes
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async addGroupMember(users) {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        await this.$store.dispatch("groups/addGroupMember", {
          groupId: this.group._id,
          payload: { members: [{ userId: users[0], role: "reader" }] }
        });
        await this.$store.dispatch("groups/retrieveGroupUsers");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async updateUsersRoles() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        if (this.selectedUsers.length > 0) {
          const newRole =
            this.selectedUsers[0].access === "reader" ? "writer" : "reader";
          const usersRoles = this.selectedUsers.map(user => ({
            userId: user.id,
            role: newRole
          }));

          await this.$store.dispatch("groups/updateGroupMembers", {
            groupId: this.group._id,
            payload: { usersRoles }
          });

          await this.$store.dispatch("groups/retrieveGroupUsers");

          this.clearAllSelectedUsers();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async removeUser(userId) {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        await this.$store.dispatch("groups/removeGroupMembers", {
          groupId: this.group._id,
          payload: { usersIds: [userId.userId] }
        });
        await this.$store.dispatch("groups/retrieveGroupUsers");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
