<template>
  <b-table
    ref="innerUserTableRef"
    :items="users"
    :fields="fields"
    :sort-by.sync="_sortBy"
    :sort-desc.sync="_sortDesc"
    :selectable="selectable"
    :select-mode="selectMode"
    responsive="lg"
    outlined
    hover
    show-empty
    small
    @row-selected="onRowSelected"
    v-bind="$attrs"
  >
    <template #empty>
      <h6 class="text-center text-italic text-uppercase">
        {{ $t("page.condominium.emptyList") }}
      </h6>
    </template>
    <template #cell(selected)="{ rowSelected }">
      <template v-if="rowSelected">
        <span aria-hidden="true">&check;</span>
        <span class="sr-only">Selected</span>
      </template>
      <template v-else>
        <span aria-hidden="true">&nbsp;</span>
        <span class="sr-only">Not selected</span>
      </template>
    </template>
    <template #head()="data">
      <div
        :class="{ clickable: data.field.sort }"
        @click="data.field.sort && setSort(data.column)"
      >
        {{ $t(data.label) }}
        <svg
          v-if="data.column === sortBy"
          viewBox="0 0 16 16"
          class="sort-icon"
        >
          <path fill-rule="evenodd" :d="sortIcon"></path>
        </svg>
      </div>
    </template>
    <template #cell(fullName)="data">
      <div class="d-flex align-items-center">
        <b-avatar class="avatar mr-3" :src="data.item.photo" />
        <div style="max-width: 180px; word-wrap: break-word">
          {{ data.value }}
        </div>
        <b-icon v-if="data.item.isOwner" icon="star-fill" class="pl-1"></b-icon>
      </div>
    </template>
    <template #cell(email)="data">
      {{ data.value }}
    </template>
    <template #cell(telephone)="data">
      {{ data.value }}
    </template>
    <template #cell(role)="data">
      {{ $is1Board ? data.value : $t(`userRoles.${data.value}`) }}
    </template>
    <template #cell(registrationDate)="data">
      {{ data.value ? getFormattedDate(data.value) : "-" }}
    </template>
    <template #cell(createdAt)="data">
      <span class="text-capitalize">
        {{ data.value | date("DD MMMM YYYY") }}
      </span>
    </template>
    <template #cell(status)="data">
      <b-badge
        class="text-white font-weight-normal"
        :variant="userStatusVariant[data.value]"
      >
        {{ $t(data.value) }}
      </b-badge>
    </template>
    <template #cell(access)="data">
      {{ $t(data.value) }}
    </template>
    <template #cell(blockedBy)="data">
      <b-icon
        v-if="data.value.length > 0"
        class="icon-clickable"
        variant="danger"
        icon="exclamation-triangle"
        @click="openReportedByModal(data.value)"
      ></b-icon>
    </template>
    <template #cell(actions)="data">
      <b-row class="no-gutters justify-content-end">
        <b-col v-for="(action, index) in actions" :key="index" class="col-auto">
          <b-button
            v-if="!action.hidden || action.hidden(data.item)"
            class="ml-1 mb-1 text-white"
            :variant="action.variant"
            size="sm"
            :disabled="action.disabled && action.disabled(data.item)"
            @click="action.callbackFunc(data.item.id)"
          >
            {{ $t(action.label) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-table>
</template>

<script>
import appMixin from "./../../mixins/appMixin";
import dayjs from "dayjs";
export default {
  props: {
    users: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectMode: {
      type: String,
      default: "multi"
    }
  },
  mixins: [appMixin],
  data() {
    return {
      userStatusVariant: {
        active: "green",
        notverified: "cyan",
        invited: "yellow"
      }
    };
  },
  computed: {
    sortIcon() {
      return this._sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      }
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("update:sortDesc", value);
      }
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this._sortBy !== newSortBy) {
        this._sortBy = newSortBy;
        this._sortDesc = false;
      } else {
        this._sortDesc = !this._sortDesc;
      }
    },
    onRowSelected(items) {
      this.$emit("setSelectedItems", items);
    },
    openReportedByModal(reports) {
      this.$store.commit("blockList/SET_BLOCKED_MODAL", {
        messageParams: reports
      });
    },
    selectAllRows() {
      this.$refs.innerUserTableRef.selectAllRows();
    },
    getFormattedDate(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    clearAllSelectedRows() {
      this.$refs.innerUserTableRef.clearSelected();
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
.avatar {
  --size: 30px;
  width: var(--size);
  height: var(--size);
}
.icon-clickable {
  cursor: pointer;
}
</style>
