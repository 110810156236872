<template>
  <div class="d-flex row no-gutters">
    <div v-if="switchLabel" :class="`mr-3 ${switchLabelClass}`">
      {{ $t(switchLabel) }}
    </div>

    <label
      :class="className"
      tabindex="0"
      role="checkbox"
      @keydown.space.prevent="keyToggle"
    >
      <input
        type="checkbox"
        class="switch__input"
        :name="name"
        :checked="value"
        :disabled="disabled"
        tabindex="-1"
        @change.stop="toggle"
      />
      <div class="switch__core" :style="coreStyle">
        <div class="switch__button" :style="buttonStyle" />
      </div>
      <span
        v-if="toggled"
        class="switch__label label__right"
        :style="labelStyle"
      >
        <slot name="checked">
          <span class="font-weight-light">{{ $t(labelChecked) }}</span>
        </slot>
      </span>
      <span v-else class="switch__label label__left" :style="labelStyle">
        <slot name="unchecked">
          <span class="font-weight-light">{{ $t(labelUnchecked) }}</span>
        </slot>
      </span>
    </label>
  </div>
</template>

<script>
const px = value => {
  return `${value}px`;
};
const translate = (x, y) => {
  return `translate(${x}, ${y})`;
};

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    color: {
      type: Object,
      default: function() {
        return {
          checked: "#28a745",
          unchecked: "red",
          disabled: "#ddd"
        };
      }
    },
    height: {
      type: Number,
      default: 24
    },
    width: {
      type: Number,
      default: 70
    },
    labels: {
      type: Object,
      default: function() {
        return {
          checked: "toggleOn",
          unchecked: "toggleOff"
        };
      }
    },
    switchLabel: {
      type: String,
      default: ""
    },
    switchLabelClass: {
      type: String,
      default: ""
    },
    name: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    sync: {
      type: Boolean,
      default: false
    },
    speed: {
      type: Number,
      default: 300
    },
    switchColor: {
      type: Object,
      default: function() {
        return {
          checked: "white",
          unchecked: "white"
        };
      }
    },
    cssColors: {
      type: Boolean,
      default: false
    },
    margin: {
      type: Number,
      default: 3
    },
    fontSize: {
      type: Number,
      default: 14
    },
    tag: {
      type: String
    }
  },
  watch: {
    value: {
      handler(value) {
        if (this.sync) {
          this.toggled = !!value;
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      toggled: !!this.value
    };
  },
  computed: {
    className() {
      return [
        "base__switch",
        {
          toggled: this.toggled,
          disabled: this.disabled
        }
      ];
    },
    coreStyle() {
      return {
        width: px(this.width),
        height: px(this.height),
        backgroundColor: this.cssColors
          ? null
          : this.disabled
          ? this.colorDisabled
          : this.colorCurrent,
        borderRadius: px(Math.round(this.height / 2))
      };
    },
    buttonRadius() {
      return this.height - this.margin * 2;
    },
    distance() {
      return px(this.width - this.height + this.margin);
    },
    buttonStyle() {
      const transition = `transform ${this.speed}ms`;
      const margin = px(this.margin);
      const transform = !this.toggled
        ? translate(this.distance, margin)
        : translate(margin, margin);
      const background = this.switchColor ? this.switchColorCurrent : null;
      return {
        width: px(this.buttonRadius),
        height: px(this.buttonRadius),
        transition,
        transform,
        background
      };
    },
    labelStyle() {
      return {
        lineHeight: px(this.height),
        fontSize: this.fontSize ? px(this.fontSize) : null
      };
    },
    colorChecked() {
      return this.color.checked;
    },
    colorUnchecked() {
      return this.color.unchecked;
    },
    colorDisabled() {
      return this.color.disabled;
    },
    colorCurrent() {
      return this.toggled ? this.colorChecked : this.colorUnchecked;
    },
    labelChecked() {
      return this.labels.checked;
    },
    labelUnchecked() {
      return this.labels.unchecked;
    },
    switchColorChecked() {
      return this.switchColor.checked;
    },
    switchColorUnchecked() {
      return this.switchColor.unchecked;
    },
    switchColorCurrent() {
      return this.toggled ? this.switchColorChecked : this.switchColorUnchecked;
    }
  },
  methods: {
    keyToggle(event) {
      if (this.disabled) {
        return;
      }
      this.toggle(event);
    },
    toggle(event) {
      const toggled = !this.toggled;
      if (!this.sync) {
        this.toggled = toggled;
      }
      this.$emit("input", toggled);
      this.$emit("change", {
        value: toggled,
        tag: this.tag,
        srcEvent: event
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.base__switch {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
  font-size: 10px;
  cursor: pointer;
  .switch__input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
  }
  .switch__label {
    position: absolute;
    top: 0;
    font-weight: 600;
    color: white;
    z-index: 1;
    &.label__left {
      left: 10px;
    }
    &.label__right {
      right: 10px;
    }
  }
  .switch__core {
    display: block;
    position: relative;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: border-color 0.3s, background-color 0.3s;
    user-select: none;
    .switch__button {
      display: block;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      border-radius: 100%;
      background-color: #fff;
      z-index: 2;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}
</style>
