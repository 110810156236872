<template>
  <b-row class="no-gutters ml-n3">
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <search-input
        v-model="searchInput"
        label="search"
        :placeholder="$t('page.groupManagement.searchPlaceholder')"
        @search="search"
      ></search-input>
    </b-col>
  </b-row>
</template>

<script>
import SearchInput from "../Base/BaseSearchInput";
export default {
  components: {
    SearchInput
  },
  data() {
    return {
      searchInput: ""
    };
  },
  mounted() {
    this.searchInput = this.filterSearch;
  },
  computed: {
    filterSearch: {
      get() {
        return this.$store.getters["groups/getManagementSearch"];
      },
      set(value) {
        this.$store.commit("groups/SET_MANAGEMENT_SEARCH", value);
      }
    }
  },
  methods: {
    search() {
      this.filterSearch = this.searchInput;
      this.retrieveGroupUsers();
    },
    async retrieveGroupUsers() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("groups/retrieveGroupUsers");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
